<template>
  <div class="accountSettings">
    <div class="topHeader">
      <div class="bannerTit">
        <span>账号设置</span>
      </div>
    </div>
    <div class="listBox">
      <div class="order-left">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-vertical-demo"
          @select="handleSelect"
        >
          <el-submenu index="1">
            <template slot="title">
              <SvgIcon
                iconClass="zhsz"
                style="margin-right: 8px; color: rgba(0, 0, 0, 0.4)"
              />
              <span :class="index == '1' ? 'isActive' : ''">账号设置</span>
              <!--  -->
            </template>
            <el-menu-item index="/PersonalInformation">个人信息</el-menu-item>
            <el-menu-item index="/AccountManagement">账号管理</el-menu-item>
            <el-menu-item index="/RealNameAuthentication"
              >实名认证</el-menu-item
            >
            <el-menu-item index="/AssociatedEnterprise">关联企业</el-menu-item>
          </el-submenu>
          <el-submenu
            index="2"
            v-if="userInfo.manuId && userInfo.roles[0].roleId == 4"
          >
            <template slot="title">
              <SvgIcon
                iconClass="qysz"
                style="margin-right: 8px; color: rgba(0, 0, 0, 0.4)"
              />
              <span :class="index == '2' ? 'isActive' : ''">企业设置</span>
            </template>
            <el-menu-item index="/Information">企业信息</el-menu-item>
            <el-menu-item index="/EnterpriseSeal">企业签章</el-menu-item>
            <el-menu-item @click="toQualification">企业资质</el-menu-item>
            <el-menu-item index="/personnelManagement">人员管理</el-menu-item>
            <el-menu-item index="/Account">账户管理</el-menu-item>
          </el-submenu>
          <el-menu-item index="/MyMessage">
            <div slot="title" style="font-size: 16px;">
              <SvgIcon
                iconClass="wdxx"
                style="margin-right: 8px; color: rgba(0, 0, 0, 0.4)"
              />
              <span slot="title">我的消息</span>
            </div>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="order-right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import { mapState } from "vuex";
export default {
  name: "accountSettings",
  data() {
    return {
      index: "0",
    };
  },
  mounted() {},
  methods: {
    handleSelect(key, indexPath) {
      this.index = indexPath[0] || "0";
      this.$router.push(key);
    },
    toQualification() {
      if (
        this.userInfo.signatureStatus == "0" ||
        this.userInfo.signatureStatus == "1"
      ) {
        this.$router.push({ path: "/EnterpriseQualification" });
      } else {
        this.$message.error(
          "您的企业尚未授权签章，请完成签章认证后开启更多企业功能！"
        );
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    activeIndex() {
      return this.$route.path;
    },
  },
};
</script>

<style lang="less" scoped>
.el-button--success {
  background-color: #0dac65;
}
.isActive {
  color: #0dac65;
}
.el-button--success.is-plain {
  background-color: #fff;
  border-color: #0dac65;
  color: #0dac65;
}
/deep/.el-menu .el-menu--inline {
  box-sizing: border-box;
  margin-left: 20px;
  overflow: hidden;
}
/deep/.el-submenu__title {
  font-size: 16px;
}
.accountSettings {
  width: 100%;
  .topHeader {
    width: 100%;
    height: 200px; /* 让高度自适应，以保持图片的原始纵横比 */
    background: url("../../assets/images/bidProject/jpHeader_copy.png")
      center/cover no-repeat;
    .bannerTit {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      font-weight: 600;
      font-size: 28px;
      color: #ffffff;
      position: relative;
      span {
        position: absolute;
        bottom: 36px;
      }
    }
  }
  .listBox {
    width: 1200px;
    min-height: 75vh;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    .order-left {
      width: 200px;
    }
    .order-right {
      flex: 1;
    }
  }
}
</style>
